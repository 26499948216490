import React from 'react';
import '../Body.css';

export default function Body() {
  return (
    <div className="about-section">
      <strong  className='about-title'>      Kinowave - Towards Omega Civilization 
      </strong>
      
      <p className="description">
      Kinowave is pioneering the path toward sustainable communities, specializing in clean energy generation, storage, and efficient cooling technology to deliver carbon-free solutions. Our mission is to make clean energy accessible to everyone, no matter where they are in the world. Driven by a strong commitment to environmental responsibility, we are dedicated to creating a greener planet for future generations.
      </p>
    </div>
    );
}