


import logo from './logo.svg';

import './App.css';
import Navbar from './compoents/Navbar';
import Herosection from './compoents/Herosection';
import Body from './compoents/Body';
import Card from './compoents/Card';
import Footer from './compoents/Footer';
import Overview from './compoents/Overview';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Product from './compoents/Product';
import About from './compoents/About';
import Team from './compoents/Team';
import Contact from './compoents/Contact';

import Mainblog from './compoents/Mainblog';
import Blogsection from './compoents/Blogsecetion';
import { RssDataProvider } from './compoents/Rssdatacontent';



function App() {

 const projects = [
        { id: 1, name: "Kinocil",
          img1contentoverview:"Overview",
          Overview: " Kinocil is a pioneering wave energy converter that utilizes Pascal's principle and the self-leveling effect of interconnected fluid-filled cylinders to harness the kinetic energy of ocean waves. Our design bypasses the need for complex and expensive mooring systems or permanent structures, significantly reducing costs and environmental impact. The device captures energy through dynamic linear motion generated by the movement of fluid within the cylinders. This motion is then converted into electricity using highly efficient Wells turbines. As a scalable and modular solution, Kinocil offers a sustainable and cost-effective approach to renewable energy, with successful prototype tests showcasing its potential for real-world applications and scalability."  
          ,img1:"./Kinocil Rendered Product.jpg",
          Title1:"Why Wave Energy?",
          Title1content:"Oceans have the capacity to power the entire world sustainably. Among various renewable energy sources, wave energy remains largely untapped, despite its estimated theoretical potential of 41,300 MW in India alone. The primary challenge has been creating effective relative motion between the energy extraction mechanism and ocean waves in open water."
          ,Title2:"Existing Approaches and Gaps",
          Title2content:"Wave energy has been studied for centuries, but its vast potential remains underutilized due to the lack of efficient and practical wave energy conversion systems. Existing technologies include:",
          listcontent:{
            content1title:"Point Absorber:",
            content1:"Limited by variations in wave heights and directions, making consistent energy conversion challenging."
            ,
            content2title:"Attenuator:",

            content2:"Involves high costs and complex mooring requirements, which restrict widespread adoption.",

            content3title:"Oscillating Water Column (OWC):",

            content3:"Faces low efficiency and structural challenges in harsh marine environments.",

            content4title:"Overtopping Device: ",

            content4:" Requires large, costly structures that are difficult to maintain.",

            content5title:"Oscillating Wave Surge Converter:",

            content5:"Struggles with mechanical complexity and durability issues in rough sea conditions.",

            addoncontent:"These challenges inspire our mission to transform wave energy into a viable and efficient source of power.",

          },
          Title3:"Our Solution",
          Title3content:"Kinocil addresses these challenges by eliminating the need for permanent structures or mooring, thereby reducing costs and enhancing efficiency. Leveraging Pascal's principle and the self-leveling effect of water, our interconnected fluid-filled cylinders capture the kinetic energy of ocean waves. This energy is then converted into electricity via Wells turbines, which efficiently translate the dynamic linear motion of the fluid within the tanks into usable power."
,
          Title4:"Design Process",
          Title4content:"Our initial goal was to develop a concept that didn’t rely on permanent structures for energy extraction. Drawing inspiration from ancient water leveling devices, we created a prototype using plastic bottles connected by tubes. This early test was promising but revealed that the water flow rate between tanks was too slow. Our calculations showed that using tanks and tubes of the same diameter improved water movement, leading us to construct a new prototype that performed more effectively. We then enhanced the design with floats to serve as a power take-off mechanism, but efficiency remained suboptimal. Further testing in a dry environment showed an efficiency of over 65%, outperforming many existing wave energy systems. In collaboration with industry leaders like Mocean, we identified the Wells turbine as a highly efficient solution for power conversion. We are currently testing the Wells turbine in a mini tank to validate its efficiency before scaling up."
,
Title5:"What Sets Us Apart",
Title5content:"Traditional wave energy converters typically require costly permanent structures or mooring systems. Our design, however, harnesses the relative motion between fluid-filled tanks floating on ocean waves, eliminating the need for such infrastructure and reducing costs significantly. Additionally, our power take-off system is a major differentiator. Unlike other platforms, such as Pelamis, that rely on inefficient hydraulic systems, Kinocil supports a variety of power take-off mechanisms, including the highly efficient Wells turbines. This innovative approach combines cost-effective design with maximum energy conversion efficiency, making Kinocil a scalable and environmentally friendly solution for ocean wave energy."
,
slidephoto:0,
slides :null,
Achievementstitle:"Our Achievements",
Achievementslist:{
  Achievement1:"Finalist of Cult Innovator Award 2023 by Confederation of Indian Industry",
  Achievement2:"Finalist of Entrepreneurship Development Institute of India (EDII) Hackathon 2022",
  Achievement3:"Pre-incubated at BITS Pilani Goa Campus 2022",
  Achievement4:"Winner, Himalayan Startup Trek 2022 at IIT Mandi",
  Achievement5:"Winner of the Smart India Hackathon 2022, recognized by the Government of India for developing innovative energy storage solutions.",
  Achievement6:"Finalist and top 5 team in UMO's Design X Social Global Innovation Challenge 2022.",
},
Title6:"Our Partner",
img6:"./parterlogo.png",
Title7:"Future Plan",
Title7content:"Our immediate goal is to test our prototype with the Wells turbine in a wave flume tank and produce a comprehensive validation report. Following this, we plan to build a 100-watt prototype for further testing in real ocean conditions. Within the next three years, we aim to scale up to a 10 kW system capable of powering five homes, advancing Kinocil towards broader commercial viability.",


youtubesrc:"https://www.youtube.com/embed/Qs0wMRCvAKg?autoplay=1&mute=1" 
},
{ id: 2, name: "Ellergy",
  img1contentoverview:"Overview",
  Overview: "Ellergy is a cutting-edge energy storage system that addresses the intermittent nature of renewable energy sources. Utilizing a vacuum-based approach inspired by atmospheric pressure principles, Ellergy stores surplus energy during peak renewable output and releases it during periods of low generation. Unlike conventional methods, our system is free from geographical constraints, allowing for scalable deployment in diverse environments. Ellergy provides an eco-friendly and reliable energy storage solution, effectively balancing the grid to support a sustainable energy future."
  
  ,img1:"./ellergy-1.jpg",
  Title1:" Why Energy Storage?",
  Title1content:"Renewable energy sources like wind and solar are inherently intermittent, making energy storage crucial for grid stability. Traditional methods, such as pumped hydro, are effective but face environmental and geographical limitations. In contrast, vacuum-based energy storage systems, like Ellergy, operate independently of terrain, offering a versatile solution to the challenges of integrating renewable energy into the grid. By harnessing atmospheric pressure and vacuum technology, Ellergy enables a sustainable and adaptable approach to large-scale energy storage."
  ,Title2:"Existing Approaches and Gaps",
  Title2content:"         Despite the growth of renewable energy, existing storage solutions have limitations:",
  listcontent:{
    content1title:"Pumped Hydro Storage:",
    content1:" Effective but requires specific geographical features, leading to high costs and environmental impact.",

    content2title:"Battery Storage: ",

    content2:"Suitable for short-term use but faces issues with lifespan, efficiency degradation, and environmental disposal challenges."
    ,

    content3title:"Compressed Air Energy Storage:",

    content3:"Requires extensive infrastructure and is limited by location and energy loss during conversion.",
   
    content4title:null,

            content4:null,

            content5title:null,

            content5:null,
            addoncontent:"These challenges underscore the need for innovative energy storage solutions that are both adaptable and sustainable.",
 },
  Title3:"Our Solution",
  Title3content:"Ellergy uses a piston and plunger mechanism within a sealed vacuum chamber to store energy. During periods of excess renewable energy, the piston creates a vacuum, storing the energy as potential energy through a pressure differential with the atmosphere. When needed, the stored energy is released as atmospheric pressure pushes the piston back, providing a stable energy supply. This vacuum-based system is scalable and environmentally friendly, addressing the limitations of traditional storage methods while offering high energy efficiency."
,
  Title4:"Design Process",
  Title4content:"Our journey began by exploring the concept of vacuum-based energy storage using a piston and plunger setup. Initially, the energy density was low, leading us to enhance the pressure differential within the chamber. We iteratively improved the design, focusing on advanced sealing technologies and optimizing the piston mechanism to reduce energy loss. Through rigorous testing and prototyping, we developed a system capable of maintaining a vacuum over extended periods. Real-time sensors and control systems were integrated to monitor and adjust performance, ensuring efficient energy storage and release."
,
Title5:"How It Is Different",
Title5content:"Ellergy stands out by enabling energy storage without geographical or environmental constraints. Traditional systems like pumped hydro are limited by location, while battery storage faces issues with efficiency and disposal. Our vacuum-based technology operates independently of terrain, with no inherent energy loss. The continuous vacuum creation maximizes energy retention, offering a sustainable and adaptable storage solution. This unique approach ensures that Ellergy can be deployed in a wide range of environments, making it a versatile option for stabilizing renewable energy grids."
,
slidephoto:1,
slides : [
  { id: 1, src: './ellergy-2.jpg', alt: 'Image 1' },
  { id: 2, src: './ellergy-3.jpg', alt: 'Image 2' },
  { id: 3, src: './ellergy-4.jpg', alt: 'Image 3' },
],

Achievementstitle:"Our Achievements",
Achievementslist:{

Achievement1:"Winner, Himalayan Startup Trek 2022 at IIT Mandi",
Achievement2:"Winner of the Smart India Hackathon 2022, recognized by the Government of India for developing innovative energy storage solutions.",
Achievement3:"Finalist and top 5 team in UMO's Design X Social Global Innovation Challenge 2022.",
},

Title6:"Our Partner",
img6:"./parterlogo.png",
Title7:"Future Plan",
Title7content:"Our next step is to optimize the system's efficiency by enhancing pressure control and minimizing friction. We aim to develop a pilot product for residential and large-scale renewable installations. By partnering with renewable energy providers, we plan to validate Ellergy's performance in real-world scenarios, improving scalability and reducing costs. Our ultimate goal is to contribute to a sustainable energy landscape by providing reliable, eco-friendly storage solutions that support global renewable energy adoption.",


youtubesrc:"https://www.youtube.com/embed/Qs0wMRCvAKg?autoplay=1&mute=1" 
},


{ id: 3, name: "Evaporative Cooler",
  img1contentoverview:"Overview",
  Overview: "Cooling demand is projected to triple globally by 2050, which will significantly increase greenhouse gas emissions. In India, air conditioning already accounts for 10% of electricity consumption, amplifying the effects of global warming due to increased fossil fuel usage. To address this growing environmental concern, we are developing a low-cost, innovative evaporative cooling system. Our technology is designed to reduce the energy load while providing efficient cooling, without increasing humidity levels. This sustainable approach aims to provide an eco-friendly solution to the rising cooling demands."
  
  ,img1:"./Cooler.jpg",
  Title1:" Why Cooling Innovation ?",
  Title1content:"As temperatures rise globally, the demand for cooling solutions is escalating, particularly in tropical regions like India. However, conventional cooling technologies such as air conditioners have limitations. They are energy-intensive, typically reaching a maximum Coefficient of Performance (COP) of 4, and contribute significantly to greenhouse gas emissions. Additionally, traditional natural evaporative coolers tend to increase humidity, making them uncomfortable in already humid climates. Our innovative cooling system addresses these gaps by offering an energy-efficient, low-humidity solution that leverages the natural process of evaporation."
  ,Title2:"Existing Approaches and Gaps",
  Title2content:"Despite the availability of various cooling technologies, existing solutions have notable limitations:",
  listcontent:{
    content1title:"Natural Evaporative Coolers:",
    content1:" These increase indoor humidity, which can lead to discomfort, especially in humid climates.",

    content2title:"Air Conditioners:",

    content2:"While effective, they typically have a maximum COP of 4, meaning they consume a significant amount of energy, which contributes to fossil fuel dependence and carbon emissions."
    ,

    content3title:null,

    content3:null,
   
    content4title:null,

            content4:null,

            content5title:null,

            content5:null,
            addoncontent:"These gaps highlight the need for a cooling solution that is both energy-efficient and capable of providing comfort without raising indoor humidity levels.",
 },
  Title3:"Our Solution",
  Title3content:"Our innovative evaporative cooling system operates on the principles of natural evaporation, which consumes significant latent heat, making it highly effective for cooling. Unlike traditional evaporative coolers, our technology does not increase environmental humidity, thereby enhancing cooling efficiency. With a projected COP of 20, our system is designed to consume far less energy than conventional air conditioners, reducing the overall carbon footprint while providing sustainable and efficient cooling."
,
  Title4:"Design Process",
  Title4content:"Our journey began by exploring natural cooling methods and identifying evaporation as a powerful mechanism due to its high latent heat absorption. We focused on combining the benefits of evaporative cooling with the energy efficiency of natural cooling methods. After extensive testing and prototyping, we developed a system that maximizes the cooling effect of evaporation without increasing humidity. By optimizing the balance between energy consumption and cooling performance, we have created a prototype that is both cost-effective and eco-friendly. This design process has laid the groundwork for a cooling solution that is efficient, scalable, and adaptable for various environments."
,
Title5:"How It Is Different",
Title5content:"Our evaporative cooling system achieves a COP of 15, which is significantly higher than the typical COP of 4 found in conventional air conditioners. This means our technology consumes substantially less energy, making it a sustainable alternative. Additionally, unlike standard evaporative coolers that raise humidity levels, our system maintains optimal indoor air quality without adding moisture to the environment. This combination of high energy efficiency and low humidity output sets our solution apart from traditional cooling systems, offering an eco-friendly and cost-effective way to meet cooling demands."
,
slidephoto:0,
slides :null,

Achievementstitle:"Our Achievements",
Achievementslist:{

Achievement1:"Global Top 30 Teams in the BeChangeMakers 2024 program.",
Achievement2:"Finalist of the Vishwakarma Award 2024 for innovative cooling solutions.",
Achievement3:"Established a Cooling Community with the support of United People Global (UPG), dedicated to promoting sustainable cooling technologies.",
},

Title6:"Our Partner",
img6:"./parterlogo.png",
Title7:"Future Plan",
Title7content:"Following the successful testing of our prototype, we aim to develop a streamlined governing equation that will enhance the technology's scalability. Our next step involves creating modular cooling systems suitable for various applications, including households, offices, and industrial facilities. By refining our design and focusing on modularity, we aim to provide tailored cooling solutions that cater to different installation needs. Our long-term vision is to contribute to a sustainable future by making efficient cooling accessible and affordable for a wide range of users.",


youtubesrc:"https://www.youtube.com/embed/Qs0wMRCvAKg?autoplay=1&mute=1" 
},
];
  return (
    <div className='app'>

<Router>
<Navbar/>
<div className="content">

      <RssDataProvider>
      <Routes>   
      <Route path="/" element={ <>
        <Herosection  />
        <Body/>
        <Card/>
        <Mainblog/>
        </>} />
        <Route path="/products" element={ <Product title="products"/>} />
        <Route path="/about" element={ <>
        <About title="About" />
        </>
      } />
          <Route path="/team" element={

<Team  title="Team"/>
}/>

<Route path="/contactus" element={<>
  <Contact title="Contact Us"/>

  </>
  }/>
         <Route path="/blogs" element={
          <Blogsection  title="Blog" />
        }/>
        <Route path="/Kinocil" element={<Overview title="Kinocil" product={projects[0]}  />}/>
        <Route path="/Ellergy" element={<Overview title="Ellergy" product={projects[1]}  />} />
        <Route path="/Evaporative Cooler" element={<Overview title="Evaporative Cooler" product={projects[2]}  />} />
      </Routes>
      </RssDataProvider>
      </div>

      <Footer/>
 </Router>
 </div>

  );
}

export default App;
