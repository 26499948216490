import React, {useContext } from "react";
import LandingBlog from "./Landingpageblog.js";
import { RssDataContext } from "./Rssdatacontent";


function App() {
 
  const { rssData } = useContext(RssDataContext);


  return (
      
      <div className="projects-section">
      <h2 className="projects-title">Our Publications</h2>
      <div className="cards-container">
      
        {rssData.slice(0, 2).map((item, index) => {
          const img = item.images && item.images.length > 1 
            ? item.images[1] 
            : "flower.png"; 

          return (
              <LandingBlog
                key={index}
                id={index}
                image={img}
                link={item.link}
                title={item.title}
              />
          );
        })}
        </div>
        </div>
  );
}

export default App;