

import React, { useEffect, useState } from 'react';
import "../Hero_section.css";
import { ReactTyped as Typed } from 'react-typed';

export default function Herosection() {
  document.title = "KinowAVE";

  const [videoClass, setVideoClass] = useState('video-full-width');
  const [showText, setShowText] = useState(false);
  const [removeBlink, setRemoveBlink] = useState(false); 
  const [videoLoaded, setVideoLoaded] = useState(false); 

  useEffect(() => {
    const timer = setTimeout(() => {
      setVideoClass('video-shrink');
    }, 2000); 

    const textTimer = setTimeout(() => {
      if (videoLoaded) {
        setShowText(true); // Show text after video is fully loaded
      }
    }, 4000);

    const removeBlinkTimer = setTimeout(() => {
      setRemoveBlink(true); // Stop blinking after typing finishes
    }, 7000); 

    return () => {
      clearTimeout(timer);
      clearTimeout(textTimer);
      clearTimeout(removeBlinkTimer); 
    };
  }, [videoLoaded]); 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleVideoLoaded = () => {
    setVideoLoaded(true); // Set the state to indicate that the video has loaded
  };

  return (
    <div className="App">
      <video
        className={videoClass}
        autoPlay
        loop
        muted
        playsInline
        onLoadedData={handleVideoLoaded} 
      >
        <source src={`${process.env.PUBLIC_URL}/wave.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {showText && ( // Only render the Typed component when showText is true
        <div className="about-section-hero">
          <Typed
            strings={[
            "We create breakthrough technologies to tackle the world's toughest climate challenges."
            ]}
            typeSpeed={20}
            backSpeed={20}
            showCursor={false}
            loop={false}
            className="description-hero"
          />
        </div>
      )}
    </div>
  );
}