import React, { useContext ,useEffect}from "react";
import Blog from "./blog.js"; // Import the Blog component
import { RssDataContext } from "./Rssdatacontent";
import '../Blogsection.css';
function BlogSection(props ) { // Receive rssData as a prop
  document.title="KinowAVE-"+props.title;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { rssData } = useContext(RssDataContext);

  return (
    <div className="blog-body contactscreen-productmain">
      <h2 className="blog-h2">Our Publications</h2>
    <div className="whole">
      
      {rssData.map((item, index) => {
        const img = item.images && item.images.length > 1 
          ? item.images[1] 
          : "flower.png"; // Use default image if not available

        return (
          <Blog
            key={index}
            image={img}
            description={item.description}
            link={item.link}
            title = {item.title.length <= 20 ? item.title : item.title.slice(0,22)+"..."}
          />
        );
      })}
     
    </div>
    </div>
  );
}

export default BlogSection;