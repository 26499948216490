import React, { useState,useEffect } from "react";
import '../contactsection.css';

function ContactForm(props) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [message, setMessage] = useState('');
    const [consent, setConsent] = useState(false);
    const [newsletter, setNewsletter] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    document.title="KinowAVE-"+props.title;

    useEffect(() => {
      window.scrollTo(0,0);
     },[]);

    const validateForm = () => {
        let isValid = true;
        let validationMessage = '';

        if (!firstName) {
            validationMessage = 'First Name is required.';
            isValid = false;
        } else if (!lastName) {
            validationMessage = 'Last Name is required.';
            isValid = false;
        } else if (!email) {
            validationMessage = 'Email is required.';
            isValid = false;
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(email)) {
                validationMessage = 'Please enter a valid email address.';
                isValid = false;
            }
        }

        if (!contactNo) {
            validationMessage = 'Contact Number is required.';
            isValid = false;
        } else if (!/^\d{10}$/.test(contactNo)) {
            validationMessage = 'Please enter a valid 10-digit contact number.';
            isValid = false;
        }

        if (!message) {
            validationMessage = 'Message is required.';
            isValid = false;
        }

        if (!consent) {
            validationMessage = 'You must agree to the terms and conditions.';
            isValid = false;
        }

        setErrorMessage(validationMessage);
        return isValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validateForm()) {
            setIsSubmitting(true);
            setErrorMessage('');

            const formData = {
                contactname: firstName + " " + lastName,
                contactemail: email,
                contactcontactNo: contactNo,
                contactmessage: message,
                consent: consent,
                newsletter: newsletter,
            };

            try {
                const response = await fetch('https://kinowave.tech/send_email.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams(formData).toString(),
                });

                const result = await response.json();

                if (response.ok && result.success) {
                    setIsSuccess(true);
                    setTimeout(() => {
                        setIsSuccess(false);
                        resetForm();
                    }, 1000);
                } else {
                    setErrorMessage(result.error || 'An error occurred. Please try again.');
                }
            } catch (error) {
                setErrorMessage('An error occurred. Please try again.');
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setContactNo('');
        setMessage('');
        setConsent(false);
        setNewsletter(false);
    };

    return (
       
        <div className="wrapper">
            <div className="contactbody">
                <div className="contact-container">
                    <h1 className="heading1">Contact</h1>
                    <p className="paragraph">
                         Thank you for your interest in Kinowave. For any urgent queries, please email to <a href="mailto:info@kinowave.tech" className="anchor">info@kinowave.tech</a>.
                    </p>

                    {isSuccess && <div className="success-message">Your message has been sent successfully!</div>}
                    {errorMessage && <div className="error-message">{errorMessage}</div>}

                    <form onSubmit={handleSubmit} className="form-container">
                        <input
                            type="text"
                            id="first-name"
                            name="first-name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            className="inputfield"
                            placeholder="First Name*"
                            
                        />

                        <input
                            type="text"
                            id="last-name"
                            name="last-name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            className="inputfield"
                            placeholder="Last Name*"
                        />

                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="inputfield"
                            placeholder="Email*"
                        />

                        <input
                            type="tel"
                            id="contactNo"
                            name="contactNo"
                            value={contactNo}
                            onChange={(e) => setContactNo(e.target.value)}
                            required
                            className="inputfield"
                            placeholder="Contact Number*"
                            maxLength="10"
                            
                        />

                        <textarea
                            id="message"
                            name="message"
                            rows="4"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="inputfield"
                            placeholder="Tell us more about why you're contacting Kinowave"
                        ></textarea>

                        <div className="checkbox-container">
                            <input
                                type="checkbox"
                                id="consent"
                                name="consent"
                                checked={consent}
                                onChange={() => setConsent(!consent)}
                                required
                                className="inputfield"
                                
                                style={{width:'13px'}}
                            />
                            <label htmlFor="consent" className="label">
                                I agree that the information submitted above, including without limitation any proposal or idea does not violate any third party rights. I understand and agree that the information I submit may be used for Kinowave's business purposes and to improve Kinowave's products and services . I agree that Kinowave may contact me to follow-up about my interest in Kinowave. I agree to Kinowave's <a href="/privacy.html">privacy policy and terms</a>.
                            </label>
                        </div>

                        <div className="checkbox-container">
                            <input
                                type="checkbox"
                                id="newsletter"
                                name="newsletter"
                                checked={newsletter}
                                onChange={() => setNewsletter(!newsletter)}
                                className="inputfield"
                                style={{ width: '13px' }}
                            />
                            <label htmlFor="newsletter" className="label">
                                I would like to receive news, promotions, and information about Kinowave.You will be able to opt-out of these emails at any time
                            </label>
                        </div>

                        <button type="submit" className="buttonfield" disabled={isSubmitting}>
                            {isSubmitting ?"Sent Sucessfully": 'Submit'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;
