// Footer.js
import React, {useEffect } from "react";
import {
    Link
  } from "react-router-dom";

 
export default function Footer() {
     // to move of the window
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  return (
    <>
    <div>

    <footer className="footer-distributed">
      <div className="footer-right">
        <a href="https://www.facebook.com/kinowave.energy">  <i className="fa-brands fa-facebook"></i></a>
        <a href="https://x.com/i/flow/login?redirect_after_login=%2FKinowaveEnergy"><i className="fa-brands fa-twitter"></i></a>
        <a href="https://www.linkedin.com/company/kinowave"><i className="fa-brands fa-linkedin"></i>


          
          </a>
          <a href="https://www.instagram.com/kinowave.energy/"><i className="fa-brands fa-instagram"></i></a>
          <a href="mailto:info@kinowave.tech"><i className="fa-solid fa-envelope"></i></a>
      </div>

      <div className="footer-left">
        <p className="footer-links">
          <Link className="link-1" to="/">Home</Link>
          <Link to="about">AboutUs</Link>
          <Link to="/products">Products</Link>

          <Link to="team">Team</Link>
          <Link to="contactus" >Contact</Link>
        <Link to="/blogs">Blog</Link>

        </p>
        <p className="companyname" style={  {
          marginTop:"2%",
          color: '#ffffff',
          fontWeight:'bold'
        }
}>KinowAVE &copy; 2024</p>
      </div>
    </footer>
    </div>
    </>
  );
};
