

import React, { useState,useEffect } from "react";
import "../product.css";
import {
  
    Link,
  } from "react-router-dom";
  import { useNavigate } from 'react-router-dom';


export default function Product(props) {


    const [activeCard, setActiveCard] = useState(null); // Correct the naming

    const handleMouseEnter = (index) => {
      setActiveCard(index); // Use correct state setter function
    };
  
    const handleMouseLeave = () => {
      setActiveCard(null); // Reset active card when the cursor leaves
    };
  document.title="KinowAVE-"+props.title;

  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(link); // Use the card's link directly
  };
    const cards = [
      {
        id: 1,
        title: "Kinocil",
        description: "Kinocil is a pioneering wave energy converter that utilizes Pascal's ....",
        img: './Kinocil Rendered Product.jpg',
// the photos should be in public folder to render
        link: "/Kinocil",
      },
      {
        id: 2,
        title: "Ellergy",
        description: "Ellergy is a cutting-edge energy storage system that addresses ...",
        img: '../ellergy-1.jpg',
        link: "/Ellergy",
      },
      {
        id: 3,
        title: "Evaporative Cooler",
        description: "Cooling demand is projected to triple globally by 2050, ...",
        img: './Cooler.jpg',
        link: "/Evaporative Cooler",
      },
    ];

    // to go to the top of the page
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div className="projects-section productmain product-head">
    <h2 className="projects-title project-title-responsive">Our Products</h2>

<div className="cards-container product-container">
{cards.map((card, index) => (
<div id={card.id} 

onClick={() => handleClick(card.link)}//passing the link directly
key={card.id}
className={`card ${activeCard === index ? "active" : ""}`}
onMouseEnter={() => handleMouseEnter(index)}
onMouseLeave={handleMouseLeave}
>
<div className="card-content">
<img src={card.img} alt={card.title} className="card-image" />
<h3 className="card-title">{card.title}</h3>
<Link
  to={card.link}
  className="card-button"
>
  View More
</Link>
</div>
</div>
))}
</div>
</div>


 )
}
