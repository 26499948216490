

import React, { useState } from "react";
import "../card.css";
import {
  Link
} from "react-router-dom";
import { useNavigate } from 'react-router-dom';

export default function Card() {


  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(link); // Use the card's link directly
  };



    const [activeCard, setActiveCard] = useState(null); // Correct the naming

    const handleMouseEnter = (index) => {
      setActiveCard(index); // Use correct state setter function
    };
  
    const handleMouseLeave = () => {
      setActiveCard(null); // Reset active card when the cursor leaves
    };

    const cards = [
      {
        id: 1,
        title: "Kinocil",
        description: "Passionate about nnn creating web applications.",
        img: './Kinocil Rendered Product.jpg',

        link: "/Kinocil#",
      },
      {
        id: 2,
        title: "Ellergy",
        description: "I design user-centric UI/UX experiences.",
        img: '../ellergy-1.jpg',
        link: "/Ellergy#",
      },
    ];
  return (
    <div className="projects-section home-project">
            <h2 className="projects-title">Products</h2>

  <div className="cards-container">
  {cards.map((card, index) => (
    <div
    onClick={() => handleClick(card.link)}//passing the link directly
      key={card.id}
      className={`card ${activeCard === index ? "active" : ""}`}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={handleMouseLeave}
    >
      <div className="card-content">
        <img src={card.img} alt={card.title} className="card-image" />
        <h3 className="card-title">{card.title}</h3>
        <Link
          to={card.link}
          className="card-button"
          rel="noopener noreferrer"
        >
          View More
        </Link>
      </div>
    </div>
  ))}
</div>
</div>
)
}
