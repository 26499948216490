import React, { useEffect } from 'react';
function Blog(props) {
  // Parallax scrolling effect
   useEffect(() => {
  
    document.addEventListener("DOMContentLoaded", function () {
      const parallaxImage = document.querySelector(".hover-image");

      window.addEventListener("scroll", () => {
        const scrollPosition = window.pageYOffset;
        const imagePosition = parallaxImage.getBoundingClientRect().top + scrollPosition;
        const parallaxOffset = (scrollPosition - imagePosition) * 0.3; // Adjust parallax strength here
        parallaxImage.style.transform = `translateY(${parallaxOffset}px)`;
      });
    })
  
  

   }, []);

  

  return (
    
    <div className="blog-card-container">
      <a href={props.link}>
      <div className="blog-image-wrapper">
        <div
          className="blog-hover-image"
          style={{ backgroundImage: `url(${props.image})` }}
        />
        </div>
        <h2 className='blog-hover-title'>{props.title}</h2>
        <p className="blog-hover-description">{props.description}</p>
        <div className="blog-card-footer">
        <i className="fas fa-info-circle blog-footer-icon"></i> 
        Learn More
        </div> 
      </a>
    </div>
  );
}

export default Blog;