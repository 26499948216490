import React, {useEffect } from 'react';
import "../About_us.css";


function About(props){
    document.title="KinowAVE-"+props.title;

    React.useEffect(() => {
        const doAnimations = () => {
          const animatables = document.querySelectorAll('.animatable');
    
          animatables.forEach((animatable) => {
            const rect = animatable.getBoundingClientRect();
            const isVisible = rect.top < window.innerHeight * 0.8 && rect.bottom > 0;

            if (isVisible) {
                // Check if the element is already animated
                if (!animatable.classList.contains('animated')) {
                    animatable.classList.add('animated');
                    // Determine the direction for animation
                    animatable.classList.add(
                    rect.left > window.innerWidth / 2 ? 'bounceInLeft' : 'bounceInRight'
                    );
                }
            } 
          });
          
        };

        const debounce = (func, delay) => {
            let timeoutId;
            return function (...args) {
              if (timeoutId) {
                clearTimeout(timeoutId); // Clear the previous timeout
              }
              timeoutId = setTimeout(() => {
                func.apply(this, args); // Call the function after the delay
              }, delay);
            };
        };

        const checkInitialVisibility = () => {
            doAnimations(); // Trigger animations for visible sections
        };
    
        const handleScroll = debounce(() => {
            doAnimations(); // Your animation logic
        }, 70); 
    
        window.addEventListener('scroll', handleScroll);
        checkInitialVisibility();
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
       useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return(<div>
         <div className="about-us-body">
            <section className="about-us about-us-page-section">
                <div className="two-column left-padding">
                    <div className="text-content">
                        <h2 className="about-heading">About Us</h2>
                        <p>
                            As the world faces an impending 50% surge in global energy demand by 2030, along with the undeniable impact of fossil fuel use—responsible for nearly 70% of global warming—Kinowave is dedicated to driving sustainable change. Our mission is to reduce fossil fuel dependency and secure a sustainable future by focusing on three key pillars: clean energy generation, advanced energy storage, and optimising energy efficiency. By addressing the core challenges of renewable energy transition, we aim to mitigate environmental threats and build a lasting legacy for a cleaner, greener world.
                        </p>
                    </div>
                    <div className="image-content animatable bounceInLeft">
                        <img src="/About_us_img1.jpg" alt="Energy Solutions" />
                    </div>
                </div>
            </section>

            <section className="what-we-do about-us-page-section">
                <div className="two-column right-padding">
                    <div className="image-content animatable bounceInRight">
                        <img src="/About_us_img2.jpg" alt="Wave Energy Technology" />
                    </div>
                    <div className="text-content">
                        <h2 className="about-heading">What Do We Do?</h2>
                        <p>
                            At Kinowave, we are committed to developing innovative solutions that address the global energy crisis from multiple perspectives. Our work focuses on three core areas:
                        </p>
                        <ul className='about-ul'>
                            <li className="about-listitem">
                                <p><strong>Sustainable Energy Generation:</strong> We design and develop renewable energy systems that harness natural forces—such as our pioneering wave energy technology—to provide viable, clean alternatives to fossil fuels.</p>
                            </li>
                            <li className="about-listitem">
                                <p><strong>Advanced Energy Storage:</strong> We create cutting-edge energy storage solutions to ensure a consistent and reliable energy supply, bridging the gap between renewable generation and consumption.</p>
                            </li>
                            <li className="about-listitem">
                                <p><strong>Energy Efficiency:</strong> We focus on optimising the performance of energy consumption systems, reducing waste and enhancing cooling system efficiency.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="why-we-do-it about-us-page-section">
                <div className="two-column left-padding">
                    <div className="text-content">
                        <h2 className="about-heading">Why Do We Do It?</h2>
                        <p>
                            The global energy landscape is at a crossroads. With the growing threat of climate change and escalating energy demands, the reliance on fossil fuels is no longer sustainable. We believe in a future where energy is clean, accessible, and efficient. Our mission is driven by the urgent need to reduce greenhouse gas emissions and foster a sustainable energy infrastructure for future generations. By developing transformative energy solutions, we aim to drive the transition to a cleaner, greener world and leave a lasting positive impact on both the environment and society.
                        </p>
                    </div>
                    <div className="image-content animatable bounceInLeft">
                        <img src="/About_us_img3.jpeg" alt="Clean Energy Innovation" />
                    </div>
                </div>
            </section>
        </div>
    </div>);
}

export default About;