

import React, { useState } from "react";

import {
  Link
} from "react-router-dom";
import { useNavigate } from 'react-router-dom';

export default function Card(props) {

  const navigate = useNavigate();

  const handleClick = (link) => {
    window.open(link, "_blank");
  };
    const [activeCard, setActiveCard] = useState(null); // Correct the naming

    const handleMouseEnter = (index) => {
      setActiveCard(index); // Use correct state setter function
    };
  
    const handleMouseLeave = () => {
      setActiveCard(null); // Reset active card when the cursor leaves
    };

return (
   
  
 
    <div
    onClick={() => handleClick(props.link)}//passing the link directly

      key={props.id}
      className={`card ${activeCard === props.id ? "active" : ""}  `}
      onMouseEnter={() => handleMouseEnter(props.id)}
      onMouseLeave={handleMouseLeave}
    >
      <div className="card-content">
        <img src={props.image} alt={props.title} className="card-image" />
        <h3 className="card-title blogcard-title" >{(props.title).slice(0,33)+"...."}</h3>
        <Link
          to={props.link}
          className="card-button"
          rel="noopener noreferrer"
        >
          View More
        </Link>
      </div>
    </div>


)
}
