
import React, { useState } from 'react';
import {
  Link
} from "react-router-dom";

export default function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav id="navbar">
        <div className="nav-wrapper">
          <div className="logo">
            <img
              src="kinlogo.jpg"
              alt="KinowAVE Logo"
              style={{
                width: '27px',
                height: '27px',
                marginRight: '8px',
              }}
            />
            <Link
              to="/"
              style={{
                fontFamily: 'Neuropolitical-Regular',
                color: '#033956',
                fontSize: '16px',
                fontWeight: 700,
              }}
            >
              KinowAVE
            </Link>
          </div>
          <ul id="menu">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/products">Products</Link></li>
            <li><Link to="team">Team</Link></li>
            <li><Link to="/blogs">Blog</Link></li>
            <li><Link to="contactus">Contact</Link></li>
          </ul>
        </div>
      </nav>

      {!isMenuOpen && (
        <div className="menuIcon" onClick={toggleMenu}>
          <span className="icon icon-bars"></span>
          <span className="icon icon-bars overlay"></span>
        </div>
      )}

      <div className={`overlay-menu ${isMenuOpen ? 'active' : ''}`}>
        {isMenuOpen && (
          <button className="close-btn" onClick={toggleMenu}>×</button>
        )}
        <ul>
          <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
          <li><Link to="/products" onClick={toggleMenu}>Products</Link></li>
          <li><Link to="team" onClick={toggleMenu}>Team</Link></li>
          <li><Link to="/blogs" onClick={toggleMenu}>Blog</Link></li>
          <li><Link to="contactus" onClick={toggleMenu}>Contact</Link></li>
        </ul>
      </div>
      </>
    );
}
